import SubHeader from "@/components/SubHeader";
import Input from "@/components/input";
import axios from "@/axios";
import loader from "@/components/loader";
import modalContent from "@/components/modal-content";
import VueMultiselect from "vue-multiselect";
import "vue-multiselect/dist/vue-multiselect.min.css";
const modalConfirm = () => import("@/components/modal-confirm");
import Button from "@/components/button";
import Checkbox from "@/components/checkbox";
import { uuid } from "vue-uuid";
import AssignedTenant from "./components/AssignTenants.vue"
import FeatureList from "./components/FeatureList.vue"
// Static use Only Remove after Proper API
import checkGapValidationInput from "../checkGapValidationInput";

import { formatSections, fetchAllRoles, fetchReportVisibility } from "@/modules/field-editor/services.js";

import checkDetails from "./components/check-details";
import sectionLists from "./components/sectionLists.vue";
import { cloneDeep } from "lodash";
const addSectionModal = () => import("./components/check-modals/add-section-modal");
const expandedPanel = () => import("./components/expanded-panel");
const addBlockModal = () => import("./components/check-modals/add-block-modal.vue");
// const addFieldModal = () => import("./components/check-modals/add-field-modal.vue");
import FieldSettingsModal from "@shared/components/modals/field-settings-modal.vue";
import GapValidationModal from "@shared/components/modals/gap-validation-modal.vue";
import Menu from "@shared/components/menu";
const AddBlockFieldModal = () => import("../../block-admin/components/addBlockAndFieldModal.vue");
import { assignedTenantIcon, featureListIcon, sectionIcon, checkIcon } from "@shared/icons.js"
// end
export default {
    name: "two-list-headerslots",
    display: "Transitions",
    order: 16,
    components: {
        Menu,
        SubHeader,
        VueMultiselect,
        AssignedTenant,
        "neo-input": Input,
        loader,
        modalContent,
        modalConfirm,
        Button,
        Checkbox,
        checkGapValidationInput,
        checkDetails,
        sectionLists,
        addSectionModal,
        expandedPanel,
        addBlockModal,
        // addFieldModal,
        FieldSettingsModal,
        GapValidationModal,
        AddBlockFieldModal,
        FeatureList
    },
    data() {
        return {
            menu: {
                selected: 0,
                menu_list: [
                    { id: 0, name: "Check Details", icon: checkIcon },
                    { id: 1, name: "Section", icon: sectionIcon },
                    { id: 2, name: "Feature List", icon: featureListIcon },
                    { id: 3, name: "Assigned Tenant", icon: assignedTenantIcon },
                ],
            },
            isSaving: false,
            gapValidationPayload: {},
            isLoading: false,
            drag: false,
            sectionList: [],
            sectionName: "",
            showSidePanel: false,
            activeFieldPanel: null,
            checkData: null,
            fieldLists: [],
            fieldToAdd: {
                section: null,
                block: null,
                field: null,
                permisions: null,
                report_visibility: null,
            },
            activeSection: null,
            blockName: "",
            tenantId: null,
            oldSectionName: "",
            oldFieldName: "",
            allRoles: [],
            fetchingRoles: false,
            expandAllSection: false,
            allReportsRoles: [],
            selected_report_option: "",
            loadingState: {
                addFields: false,
                getDependent: false,
                fetchingDetails: false
            },
            dependentsFields: [],
            activeFieldSettings: {
                field: null,
                section: null,
            },
            loadingLists: false,
            blockLists: [],
            blockSearchValue: "",
            activeGapValidation: {
                field: null,
                section: null,
            },
            customCrumbs: [],
            showBlockAddModal: false,
            listType: {
                type: "field",
                listModalTitle: "Add Field ",
                permissionModalTitle: "Field Settings",
            },
            filterFiledValue: "",
            tenantData: {
                licenseTenants: [],
                featuresLists: []
            },
            searchValues: {
                tenants: "",
                features: ""
            }
        };
    },
    async mounted() {
        this.isLoading = true;
        if (!this.$store.getters.getTenantId) {
            await this.$store.dispatch("fetchTenantId");
        }
        this.tenantId = this.$store.getters.getTenantId;
        // this.fieldLists = await this.fetchFieldsList();
        await this.getCheckDetails();
        await this.fetchAllAvailableRoles();
        await this.fetchAvailableReportVisibility();
    },
    computed: {
        isGapValidationField() {
            if (this.fieldToAdd.field?.meta) {
                const json_meta = JSON.parse(this.fieldToAdd.field.meta);
                return json_meta.use_in_gap_validation === true;
            }
        },
        filterBlockLists() {
            return this.blockLists.filter((el) => el.name?.toLowerCase().includes(this.blockSearchValue.toLowerCase()) || el.label?.toLowerCase().includes(this.blockSearchValue.toLowerCase()));
        },
        filteredFieldList() {
            return this.fieldLists.filter((el) => el.name?.toLowerCase().includes(this.filterFiledValue.toLowerCase()));
        },
        getTenantsList() {
            return this.tenantData.licenseTenants?.filter((el) => el.title?.toLowerCase()?.includes(this.searchValues.tenants.toLowerCase()))
        },
        getFeaturedList() {
            return this.tenantData.featuresLists?.filter((el) => el.name?.toLowerCase()?.includes(this.searchValues.features.toLowerCase()));
        }
    },
    methods: {
        handleFilterResult({ data, type }) {
            this.searchValues[type] = data;
        },
        async switchMenu(menu) {
            this.menu.selected = menu.id
            const functionMap = {
                'Assigned Tenant': this.getTenantsData,
                "Feature List": this.featureData
            }
            if (functionMap[menu.name]) {
                await functionMap[menu.name]()
            }
        },
        async handleSaveButton() {
            let menuMap = {
                'Assigned Tenant': this.saveTenants,
                "Feature List": this.saveFeatures,
                "Check Details": this.handleSaveCheck,
            }
            let menu_name = this.menu.menu_list.find((men) => men.id === this.menu.selected)
            await menuMap[menu_name.name]()

            // , icon: checkIcon},
            //         {id: 1, name: "Section"

        },
        selectAll({ select, type }) {
            const listMap = {
                feature: 'featuresLists',
                tenant: 'licenseTenants'
            }
            this.tenantData[listMap[type]] = this.tenantData[listMap[type]]?.map((el) => ({ ...el, checked: select }));
        },
        handleGapValidationPayload(payload) {
            this.gapValidationPayload = payload;
        },
        async fetchFieldsList() {
            let payload = {
                tenant_id: this.tenantId,
                req_offset: 0,
                req_limit: 1,
            };
            let url = `/fields/${this.tenantId}/all`;
            let result = await axios.get(url, payload);
            return result.data.fields;
        },

        // Fetching Roles
        async fetchAllAvailableRoles() {
            this.fetchingRoles = true;
            this.allRoles = await fetchAllRoles();
            this.fetchingRoles = false;

            // result.data.fields;
        },
        async fetchAvailableReportVisibility() {
            this.allReportsRoles = await fetchReportVisibility();
        },

        async getCheckDetails() {
            this.isLoading = true;
            let check_id = this.$route.params.id;
            if (check_id) {
                this.isLoading = true;
                let url = `/tenant-check/${this.tenantId}/${check_id}`;
                let { data } = await axios.get(url);
                try {
                    this.checkData = data.data;
                    this.customCrumbs = [{ name: data?.data?.name ?? "" }];
                    this.checkData.parameters = JSON.parse(this.checkData.parameters);
                    this.checkData = { ...this.checkData, expanded: true };
                    let sections = formatSections(data.data.sections);
                    this.sectionList = sections;
                    this.isLoading = false;
                } catch (error) {
                    console.log(error, ">>>Error");
                    this.isLoading = false;
                }
                this.isLoading = false;
            }
        },

        // Tenant license

        async getTenantsData() {
            this.loadingState.fetchingDetails = true;
            try {
                let tenantLists = await this.getAllTenant();
                let assignedTenantLists = await this.getAssignedTenants();
                tenantLists = tenantLists?.map((tenant) => {
                    for (let i of assignedTenantLists) {
                        if (i.id === tenant.id) {
                            return { ...tenant, checked: true };
                        }
                    }
                    return { ...tenant, checked: false };
                });
                this.tenantData.licenseTenants = tenantLists.sort((a) => a.checked ? -1 : 1)
            } catch (error) {
                console.log(error, "errror");
            }
            this.loadingState.fetchingDetails = false;

        },

        async getAllTenant() {
            try {
                let { data } = await axios.get('/tenant');
                return data.map(el => ({ id: el.id, title: el.title })) || []
            } catch (error) {
                console.log(error, "errror");
            }
        },

        async getAssignedTenants() {
            try {
                let tenant_check_id = this.$route.params.id
                let { data } = await axios.get(`/delvium-admin/checks/tenant/${tenant_check_id}`);
                return data.tenants || []
            } catch (error) {
                console.log(error, "errror");
            }
        },

        // Feaute Data
        async featureData() {
            this.loadingState.fetchingDetails = true;
            this.searchValues.features = "";
            try {
                let featureLists = await this.getAllFeaturesList();
                let assignedFeature = await this.getAssignedFeatureList();
                featureLists = featureLists?.map((feature) => {
                    for (let i of assignedFeature) {
                        if (i.id === feature.id) {
                            return { ...feature, checked: true, read_only: i.read_only };
                        }
                    }
                    return { ...feature, checked: false, read_only: false };
                });
                this.tenantData.featuresLists = featureLists.sort((a) => a.checked ? -1 : 1)
            } catch (error) {
                console.log(error, "errror");
            }
            this.loadingState.fetchingDetails = false;
        },

        async getAssignedFeatureList() {
            try {
                let check_id = this.$route.params.id;
                let { data } = await axios.get(`/tenant-check/${check_id}/check/feature`);
                return data.features || [];
            } catch (error) {
                console.log(error, "errror");
            }
        },

        // read only check feature 
        licenseReadOnlyPermission(event) {
            console.log('event :>> ', event);
            this.tenantData.featuresLists = this.tenantData.featuresLists.map((el) => {
                if (el.id === event.id) {
                    return { ...el, read_only: !event.read_only };
                } else {
                    return el;
                }
            });
        },

        async saveFeatures() {
            let tenant_check_id = this.$route.params.id
            let features = this.tenantData.featuresLists?.filter(fea => fea.checked).map(fea => ({
                "feature_id": fea.id,
                "read_only": fea.read_only
            }))
            try {
                const { data } = await axios.post(`tenant-check/feature`, {
                    tenant_check_id,
                    features
                })
                // console.log('data :>> ', data);
                if (data && data.success) this.$toast.success(data.message || 'Allocated')
            } catch (error) {
                console.log('error :>> saveTenants()', error);
            }
        },

        async saveTenants() {
            let checks = [this.$route.params.id]
            let tenants = this.tenantData.licenseTenants.filter(ten => ten.checked).map(ten => ten.id)
            try {
                const { data } = await axios.post(`delvium-admin/assign/checks`, {
                    tenants, checks
                })
                if (data) this.$toast.success(data.message || 'Checks have been assigned to the Tenants.')
            } catch (error) {
                console.log('error :>> ', error);
            }
        },

        // Delete Check

        async handleDeleteCheck(check) {
            const promise = await this.$refs["confirm-popup"].show({
                title: "Are you sure?",
                message: "This Check  will be deleted permanently. Please confirm to continue deleting this check.",
            });
            if (promise) {
                let url = `/tenant-check/${this.tenantId}/${check.id}`;
                try {
                    let { data } = await axios.delete(url);
                    this.hideSidePanel();
                    this.$router.push({
                        name: "check Admin",
                    });
                    this.$toast.success(data.message, "Check deleted ");
                } catch (error) {
                    this.$toast.error(error?.response?.data?.detail || "Failed to delete check");
                }
            }
            this.$refs["confirm-popup"].close();
        },

        // End Delete Check

        // Add Block

        async addBlock(block) {
            let section = this.activeSection;
            try {
                let url = `/tenant-check/section/${section.id}/block/add`;
                let payload = {
                    block_id: block.id,
                };
                let { data } = await axios.post(url, payload);
                let blockData = {
                    block_details: data || null,
                    block_multi: data.multi || false,
                    entity_type: "block",
                    expanded: true,
                    fields: data.fields,
                    name: data.name,
                    id: data.id,
                    edited: false,
                    max_count: data.max_count,
                    min_count: data.min_count,
                    label: data.label,
                };
                this.sectionList.find((sec) => sec.id === section.id).fields.push(blockData);
                // this.sectionList = this.sectionList.map(el => {
                //     if(el.id === section.id){
                //         el.fields.push(blockData)
                //     }
                // })
                this.$toast.success(data.message || "Block added to check");
            } catch (error) {
                this.$toast.error(error?.response?.data?.detail || "Failed to add block");
            }
            // // let check_id = this.checkData.id
            // // let url = `/tenant-check/${check_id}/section/${section.id}/block`;
            // let payload = {
            //     fields: [],
            //     id: uuid.v4(),
            //     expanded: true,
            //     entity_type: "block",
            //     created: true,
            //     edited: false,
            //     name: blockName,
            //     block_multi: false,
            //     min_count: 1,
            //     max_count: 1,
            // };
            // this.sectionList.find((sec) => sec.id === section.id).fields.push(payload);
            // this.$toast.success("Block Added");
            // this.$refs["block-modal"].closeModal();
        },

        // End Add Block

        // Remove Block

        async handleRemoveBlock(section, block) {
            block = section?.block ? section?.block : block
            section = section?.section ? section?.section : section
            const promise = await this.$refs["confirm-popup"].show({
                title: "Are you sure?",
                message: "This Block  will be deleted permanently. Please confirm to continue deleting this Block.",
            });
            if (promise) {
                let check_id = this.checkData.id;
                let url = `/tenant-check/${check_id}/section/${section.id}/block/${block.id}`;
                if (block.created) {
                    this.sectionList.map((sec) => {
                        if (sec.id === section.id) {
                            let fields = sec.fields.filter((el) => el.id !== block.id);
                            sec = sec.fields = fields;
                        }
                    });
                } else {
                    try {
                        let { data } = await axios.delete(url);
                        this.hideSidePanel();
                        this.sectionList.map((sec) => {
                            if (sec.id === section.id) {
                                let fields = sec.fields.filter((el) => el.id !== block.id);
                                sec = sec.fields = fields;
                            }
                        });

                        this.$toast.success(data.message);
                    } catch (error) {
                        this.$toast.error(error?.response?.data?.detail || "Failed to delete block");
                    }
                }
            }
            this.$refs["confirm-popup"].close();
        },

        // End Remove Block

        // section Modal control
        closeSectionModal() {
            this.$refs["show-section-modal"].closeModal();
        },

        showSectionModal() {
            this.$refs["show-section-modal"].showAddSectionModal();
        },

        // End Section Modal control

        // section add field Modal control
        closefieldAddModal() {
            // this.$refs["section-addfield-modal"].closeModal();
            this.showBlockAddModal = false;
        },
        onChangeFilterResult(event) {
            this.filterFiledValue = event;
        },

        async showfieldAddModal(section, block) {
            this.loadingState.addFields = true;
            this.filterFiledValue = "";
            // this.$refs["section-addfield-modal"].showAddFieldModal();
            this.showBlockAddModal = true;
            this.fieldToAdd.field = null;
            this.fieldLists = await this.fetchFieldsList();
            await this.fetchAvailableReportVisibility();
            let permisions =
                this.allRoles &&
                this.allRoles?.map((permision) => ({
                    ...permision,
                    title: permision.title,
                    role_read: false,
                    role_write: false,
                    role_visible: false,
                    role_mandatory: false,
                    select_all: false,
                }));
            this.fieldToAdd = {
                section: section,
                block: block ? block : null,
                field: null,
                permisions: permisions,
                all_reports_visibilty: this.allReportsRoles,
                report_visibility: null,
            };
            this.loadingState.addFields = false;
        },

        // End Section add field Modal control

        handleSelectAllPermission(role) {
            let permisions = this.fieldToAdd.permisions;
            if (role.select_all) {
                permisions.map((el) => {
                    if (el.id === role.id) {
                        el.role_read = true;
                        el.role_write = true;
                        el.role_visible = true;
                        el.role_mandatory = true;
                    }
                });
            } else {
                permisions.map((el) => {
                    if (el.id === role.id) {
                        el.role_read = false;
                        el.role_write = false;
                        el.role_visible = false;
                        el.role_mandatory = false;
                    }
                });
            }
            this.fieldToAdd.permisions = permisions;
        },

        handleSelectAllFieldPermission(role) {
            this.activeFieldPanel.field.roles.map((el) => {
                // NOTE: FIX THIS

                if (el.id === role.id) {
                    if (role.select_all) {
                        el.role_read = true;
                        el.role_write = true;
                        el.role_visible = true;
                        el.role_mandatory = true;
                    } else {
                        el.role_read = false;
                        el.role_write = false;
                        el.role_visible = false;
                        el.role_mandatory = false;
                    }
                }
            });
        },

        // Remove section

        async handleRemoveSection(section) {
            const promise = await this.$refs["confirm-popup"].show({
                title: "Are you sure?",
                message: "This section  will be deleted permanently. Please confirm to continue deleting this section.",
            });
            if (promise) {
                let url = `/tenant-check/section`;
                let payload = {
                    check_id: this.checkData.id,
                    section_id: section.id,
                };

                try {
                    let { data } = await axios.delete(url, { data: payload });
                    this.hideSidePanel();
                    this.$toast.success(data.message);
                    let newList = this.sectionList.filter((ele) => ele.id !== section.id);
                    this.sectionList = newList;
                    this.$refs["confirm-popup"].close();
                } catch (error) {
                    this.$toast.error(error?.response?.data?.detail || "Failed to remove section");
                    this.$refs["confirm-popup"].close();
                }
            }
            this.$refs["confirm-popup"].close();
        },
        async onAddField({ payload, field }) {
            const { roles, report_visibility, use_in_gap_validation } = payload;
            const { label, id, is_cascaded } = field;
            const { section, block } = this.fieldToAdd;
            if (!report_visibility) {
                this.$toast.error(`Please select a report visibility option before Adding new field`);
                return;
            }
            const payloadBody = {
                check_name: label,
                field_id: id,
                tenant_check_id: this.checkData.id,
                field_details: {
                    field_global_id: field.id,
                    section_id: section.id,
                    internal_use_only: false,
                    block_id: block ? block.id : null,
                    multi: false,
                    max_field_or_block_count: 1,
                    min_field_or_block_count: 1,
                    is_top_level: false,
                    report_visibility,
                },
                permissions: roles,
                is_cascaded,
            };
            if (use_in_gap_validation) {
                const { history, valid_gap } = payload;
                const gapValid = {
                    history: history || history == 0 ? history : 12,
                    use_in_gap_validation: true,
                    valid_gap: valid_gap || valid_gap == 0 ? valid_gap : 30,
                };
                payloadBody.field_details = { ...payloadBody.field_details, ...gapValid };
            }
            let url = `/tenant-check/section/${section.id}/field/add`;
            try {
                let { data } = await axios.post(url, payloadBody);
                if (data.section_field_id) {
                    this.sectionList.map((sec) => {
                        if (sec.id === section.id) {
                            if (block) {
                                sec.fields.map((blockData) => {
                                    if (blockData.id === block.id) {
                                        // NOTE: FIX THIS
                                        blockData.fields.push({
                                            ...payloadBody,
                                            ...payloadBody.field_details,
                                            type: field.type,
                                            report_visibility,
                                            block_id: block.id,
                                            entity_type: "field",
                                            id: data.section_field_id,
                                            label: field.label,
                                            name: field.name,
                                            placeholder: field.placeholder,
                                            roles: roles,
                                            ui_label: field.ui_label,
                                            is_custom: field.is_custom,
                                            use_in_case_creation: false,
                                        });
                                    }
                                });
                            } else {
                                // NOTE: FIX THIS
                                sec.fields.push({
                                    ...payloadBody,
                                    ...payloadBody.field_details,
                                    entity_type: "field",
                                    id: data.section_field_id,
                                    label: field.label,
                                    name: field.name,
                                    type: field.type,
                                    placeholder: field.placeholder,
                                    report_visibility,
                                    roles: roles,
                                    ui_label: field.ui_label,
                                    is_custom: field.is_custom,
                                    use_in_case_creation: false,
                                });
                            }
                        }
                    });
                    // await this.getCheckDetails()
                    this.$toast.success(data.message);
                }
                this.closefieldAddModal();
            } catch (error) {
                this.$toast.error(error?.response?.data?.detail || "Error while adding field to sections");
            }
        },
        // End Remove section

        // handle Add Field to Sections
        async addFieldToSection({ payload: paramPayload, field: paramfield }) {
            if (!paramPayload.report_visibility) {
                this.$toast.error(`Please select a report visibility option before Adding new field`);
                return;
            }
            let { block, section, field, permisions, report_visibility } = paramfield;
            let new_permisions = [];

            new_permisions = permisions?.map((permision) => {
                const payload_permision = {
                    role_id: permision.id,
                    role_read: permision.role_read,
                    role_write: permision.role_write,
                    role_visible: permision.role_visible,
                    role_mandatory: permision.role_mandatory,
                };
                return payload_permision;
            });

            let payload = {
                check_name: field.label,
                field_id: field.id,
                tenant_check_id: this.checkData.id,
                field_details: {
                    field_global_id: field.id,
                    section_id: section.id,
                    internal_use_only: false,
                    block_id: block ? block.id : null,
                    multi: false,
                    max_field_or_block_count: 1,
                    min_field_or_block_count: 1,
                    is_top_level: false,
                    report_visibility,
                },
                permissions: new_permisions,
                is_cascaded: field.is_cascaded,
            };
            if (this.isGapValidationField) {
                const { history, valid_gap } = this.gapValidationPayload;
                const gapValid = {
                    history: history || history == 0 ? history : 12,
                    use_in_gap_validation: true,
                    valid_gap: valid_gap || valid_gap == 0 ? valid_gap : 30,
                };
                payload.field_details = { ...payload.field_details, ...gapValid };
            }
            if (block && block.created) {
                this.addFieldToNewBlock(block, section, field, payload, permisions);
            } else {
                let url = `/tenant-check/section/${section.id}/field/add`;
                try {
                    let { data } = await axios.post(url, payload);
                    if (data.section_field_id) {
                        this.sectionList.map((sec) => {
                            if (sec.id === section.id) {
                                if (block) {
                                    sec.fields.map((blockData) => {
                                        if (blockData.id === block.id) {
                                            // NOTE: FIX THIS
                                            blockData.fields.push({
                                                ...payload,
                                                ...payload.field_details,
                                                type: field.type,
                                                report_visibility,
                                                block_id: block.id,
                                                entity_type: "field",
                                                id: data.section_field_id,
                                                label: field.label,
                                                name: field.name,
                                                placeholder: field.placeholder,
                                                roles: permisions.map((per) => ({ ...per, role_id: per.id })),
                                                ui_label: field.ui_label,
                                                is_custom: field.is_custom,
                                                use_in_case_creation: false,
                                            });
                                        }
                                    });
                                } else {
                                    // NOTE: FIX THIS
                                    sec.fields.push({
                                        ...payload,
                                        ...payload.field_details,
                                        entity_type: "field",
                                        id: data.section_field_id,
                                        label: field.label,
                                        name: field.name,
                                        type: field.type,
                                        placeholder: field.placeholder,
                                        report_visibility,
                                        roles: permisions.map((per) => ({ ...per, role_id: per.id })),
                                        ui_label: field.ui_label,
                                        is_custom: field.is_custom,
                                        use_in_case_creation: false,
                                    });
                                }
                            }
                        });
                        // await this.getCheckDetails()
                        this.$toast.success(data.message);
                    }
                    this.closefieldAddModal();
                } catch (error) {
                    this.$toast.error(error?.response?.data?.detail || "Error while adding field to sections");
                }
            }
        },
        hideSidePanel() {
            this.showSidePanel = false;
            this.activeFieldPanel = null;
        },
        // Add Field to new added Block
        addFieldToNewBlock(block, section, field, payload, permisions) {
            let fieldData = {
                field_id: payload.field_id,
                internal_use_only: false,
                max_field_or_block_count: payload.field_details.max_field_or_block_count,
                min_field_or_block_count: payload.field_details.min_field_or_block_count,
                is_top_level: payload.field_details.is_top_level,
                permissions: payload.permissions,
                report_visibility: payload.field_details.report_visibility,
                is_cascaded: payload.is_cascaded,
            };

            // If a gap validation field is being added to block
            // it needs to have an additional parameter.
            if (this.isGapValidationField) {
                // fieldData.history = this.gapValidationPayload.history;
                // fieldData.valid_gap = this.gapValidationPayload.valid_gap;
                // fieldData.use_in_gap_validation = true;
                const { history, valid_gap } = this.gapValidationPayload;
                const gapValid = {
                    history: history || history == 0 ? history : 12,
                    use_in_gap_validation: true,
                    valid_gap: valid_gap || valid_gap == 0 ? valid_gap : 30,
                };
                fieldData = { ...fieldData, ...gapValid };
            }

            this.sectionList.map((sec) => {
                if (sec.id === section.id) {
                    sec.fields.map((fie) => {
                        if (fie.id === block.id) {
                            fie.fields.push({
                                ...fieldData,
                                created_with_block: true,
                                block_id: block.id,
                                entity_type: "field",
                                id: uuid.v4(),
                                label: field.label,
                                name: field.name,
                                placeholder: field.placeholder,
                                roles: permisions.map((per) => ({ ...per, role_id: per.id })),
                                use_in_case_creation: false,
                            });
                        }
                    });
                }
            });
        },

        // End New block Field add

        // Save Block with Field

        async saveBlockFields(section, element) {
            let check_id = this.checkData.id;
            let url = `/tenant-check/${check_id}/section/${section.id}/block`;
            let payload = {
                tenant_id: this.tenantId,
                block_name: element.name,
                block_multi: element.block_multi,
                field_list: [],
                max_count: parseInt(element.max_count),
                min_count: parseInt(element.min_count),
            };
            this.hideSidePanel();
            element.fields.forEach((ele) => {
                payload.field_list.push(ele);
            });

            //NOTE(deependar)
            // element.fields.map(ele => payload.field_list.push(
            //     {
            //         field_id: ele.field_id,
            //         internal_use_only: ele.internal_use_only,
            //         max_field_or_block_count: ele.max_field_or_block_count,
            //         min_field_or_block_count: ele.min_field_or_block_count,
            //         is_top_level: ele.is_top_level,
            //         permissions: ele.permissions,
            //         report_visibility: ele.report_visibility
            //         }
            //     ))
            try {
                let { data } = await axios.post(url, payload);
                element.fields = data.fields;
                element.created = false;
                element.id = data.block_id;
                this.$toast.success(data.message);
            } catch (error) {
                this.$toast.error(error?.response?.data?.detail || "Failed to create block");
            }
        },
        async updateSectionFields(section) {
            // TODO connect to API
            await console.log("save section fields", section);
        },

        // End save Block with fields

        // Update block

        async handleUpdateBlock(section, block) {
            let check_id = this.checkData.id;
            let url = `/tenant-check/${check_id}/section/${section.id}/block/${block.id}`;
            let payload = {
                tenant_id: this.tenantId,
                name: block.name,
                multi: block.block_multi,
                min_count: block.min_count,

                max_count: block.max_count,
            };
            if (block.fields) payload.fields = block.fields;
            try {
                let { data } = await axios.put(url, payload);
                block.edited = false;
                this.$toast.success(data.message);
            } catch (error) {
                this.$toast.error(error?.response?.data?.detail || "Failed to update block details");
            }
        },

        // end update Block

        handleCollapseSection(section) {
            this.sectionList.filter((ele) => {
                if (ele.id === section.id) {
                    ele.expanded = !ele.expanded;
                }
            });
        },

        // Collapse block
        handleCollapseBlock(section, block) {
            this.sectionList.find((sec) => sec.id === section.id).fields.find((bl) => bl.id === block.id).expanded = !block.expanded;
        },

        // End collapse Block

        async addNewSection(sectionName) {
            if (!sectionName) return this.$toast.error("Section Name required");
            let url = `/tenant-check/${this.checkData.id}/section`;
            let payload = {
                name: sectionName,
                section_fields: [],
            };
            try {
                let { data } = await axios.post(url, payload);
                let newSection = {
                    id: data.section_id,
                    name: sectionName,
                    expanded: true,
                    blocks: [],
                    fields: [],
                };
                this.sectionList.push(newSection);
                this.$toast.success(data.message);
            } catch (error) {
                this.$toast.error(error?.response?.data?.detail || "Failed to add new section");
            }
            this.closeSectionModal();
        },

        // handle Delete Field form section and Block

        async handleDeleteField(field, section) {
            const promise = await this.$refs["confirm-popup"].show({
                title: "Are you sure?",
                message: "This field  will be deleted permanently. Please confirm to continue deleting this field.",
            });

            if (promise) {
                let url = `/tenant-check/field`;
                let payload = {
                    check_id: this.checkData.id,
                    section_field_id: field.id,
                    tenant_id: this.tenantId,
                };
                if (field.block_id && field.created_with_block) {
                    this.sectionList.map((sec) => {
                        if (sec.id === section.id) {
                            sec.fields.map((block) => {
                                if (block.id === field.block_id) {
                                    return (block.fields = block.fields.filter((el) => el.id !== field.id));
                                }
                            });
                        }
                    });
                } else {
                    try {
                        let { data } = await axios.delete(url, { data: payload });
                        this.hideSidePanel();
                        if (field.block_id) {
                            this.sectionList.map((sec) => {
                                if (sec.id === section.id) {
                                    sec.fields.map((block) => {
                                        if (block.id === field.block_id) {
                                            return (block.fields = block.fields.filter((el) => el.id !== field.id));
                                        }
                                    });
                                }
                            });
                        } else {
                            this.sectionList.map((sec) => {
                                if (sec.id === section.id) {
                                    return (sec.fields = sec.fields.filter((el) => el.id !== field.id));
                                }
                            });
                        }
                        this.$toast.success(data.message);
                    } catch (error) {
                        this.$toast.error(error?.response?.data?.detail || "Failed to delete field");
                    }
                }
            }
            this.$refs["confirm-popup"].close();
            this.handleClosePanel();
        },

        // End Delete Field from section and block

        // update Section Label
        async updateSectionLabel(sectionData) {
            if (this.oldSectionName !== sectionData.name) {
                const url = `/tenant-check/${this.checkData.id}/section/rename`;
                let payload = {
                    section_id: sectionData.id,
                    name: sectionData.name,
                };
                try {
                    const { data } = await axios.post(url, payload);
                    this.$toast.success(data.message);
                } catch (error) {
                    this.$toast.error(error?.response?.data?.detail || "Failed to rename section name!");
                }
            }
        },

        // Update field Name

        async updateFieldLabel(sectionData, checkData, silent = false) {
            if (this.oldFieldName !== checkData.label) {
                const url = `/tenant-check/${this.checkData.id}/section/${sectionData.id}/field`;
                let payload = {
                    section_field_id: checkData.id,
                    label: checkData.label,
                    permissions: checkData.roles,
                    report_visibility: checkData.report_visibility,
                    history: checkData.history,
                    valid_gap: checkData.valid_gap,
                    use_in_gap_validation: checkData.use_in_gap_validation,
                };
                try {
                    const { data } = await axios.put(url, payload);
                    if (checkData.use_in_gap_validation) {
                        const sectionList = cloneDeep(this.sectionList);
                        sectionList.forEach((section, ind1) => {
                            if (section.id === checkData.section_id) {
                                section.fields.forEach((field, ind2) => {
                                    if (field.id === checkData.block_id) {
                                        field.fields.forEach((_, ind3) => {
                                            if (_.id === checkData.id) {
                                                sectionList[ind1].fields[ind2].fields[ind3] = {
                                                    ..._,
                                                    history: checkData.history,
                                                    valid_gap: checkData.valid_gap,
                                                };
                                            }
                                        });
                                    } else {
                                        if (checkData.id === field.id) {
                                            sectionList[ind1].fields[ind2] = {
                                                ...field,
                                                history: checkData.history,
                                                valid_gap: checkData.valid_gap,
                                            };
                                        }
                                    }
                                });
                            }
                        });
                        this.sectionList = sectionList;
                        this.$refs.gapValidationModal.closeModal();
                    }
                    // }
                    if (!silent) this.$toast.success(data.message);
                } catch (error) {
                    this.$toast.error(error?.response?.data?.detail || "Failed to rename field name!");
                    throw error;
                }
            }
        },

        fieldNameClick(checkData) {
            this.oldFieldName = checkData.label;
        },

        async handleFieldChange(e) {
            let field_lists = [];
            this.sectionList.forEach((section) =>
                section.fields.forEach((field) => {
                    if (field.entity_type === "block") {
                        field.fields.forEach((blockField) => field_lists.push({ section_field_id: blockField.id, section_id: section.id, order: field_lists.length + 1 }));
                    } else {
                        field_lists.push({ section_field_id: field.id, section_id: section.id, order: field_lists.length + 1 });
                    }
                })
            );
            // if (e.moved) {

            // }
            // if(e.removed){
            //     this.$toast.success("Field removed");

            // }
            if (e.added || e.moved) {
                const url = `/tenant-check/${this.checkData.id}/fields/reorder`;
                let payload = {
                    tenant_id: this.tenantId,
                    field_list: field_lists,
                };
                try {
                    const { data } = await axios.post(url, payload);
                    this.$toast.success(data.message);
                } catch (error) {
                    this.$toast.error(error?.response?.data?.detail || "Failed to reorder section!");
                }
                // this.$toast.success("field added")
            }
        },

        async getAllFeaturesList() {
            try {
                let { data } = await axios.get(`/feature`);
                return data.features || [];
            } catch (error) {
                console.log(error, "errror");
            }
        },
        // reorder section
        async hanldeSectionReorder(e) {
            let section_list = [];
            this.sectionList.forEach((el, index) => section_list.push({ section_id: el.id, order: index + 1 }));
            if (e.moved) {
                const url = `/tenant-check/${this.checkData.id}/section/reorder`;
                let payload = {
                    tenant_id: this.tenantId,
                    section_list: section_list,
                };
                try {
                    const { data } = await axios.post(url, payload);
                    this.$toast.success(data.message);
                } catch (error) {
                    this.$toast.error(error?.response?.data?.detail || "Failed to reorder section!");
                }
                // this.$toast.success("Section Reordered");
            }
        },

        // end reorder section
        handleAddFieldChange(e) {
            console.log(e, "add field Event");
        },

        onShowPanel(element, section) {
            if (element.is_cascaded) return;
            this.handleShowPanel(element, section);
        },

        async handleShowPanel(element, section) {
            if (!element.fields) {
                element.roles = element?.roles.map((role) => ({ ...role, select_all: role.role_mandatory && role.role_read && role.role_visible && role.role_write }));
                this.showSidePanel = true;
                this.activeFieldPanel = {
                    field: element,
                    section: section,
                    is_cascaded: element.is_cascaded,
                };
                if (element.is_cascaded) {
                    this.loadingState.getDependent = true;
                    try {
                        const { data } = await axios.get(`/tenant-check/section/${section.id}/field/${element.id}/dependents`);
                        this.dependentsFields = data.dependents;
                    } catch (error) {
                        console.log("error :>> ", error);
                    }
                    this.loadingState.getDependent = false;
                }
            }
        },
        showFieldSettingsModal(element = null, section) {
            if (!element) {
                this.activeFieldSettings = {
                    field: null,
                    section: null,
                };
                return;
            }
            element.roles = element?.roles.map((role) => ({ ...role, select_all: role.role_mandatory && role.role_read && role.role_visible && role.role_write }));
            this.activeFieldSettings = {
                field: element,
                section: section,
                is_cascaded: element.is_cascaded,
            };
            this.$refs.fieldSettingsModal.showModal();
        },
        showGapValidationModal(element = null, section) {
            if (!element) {
                this.activeGapValidation = {
                    field: null,
                    section: null,
                };
                return;
            }
            this.activeGapValidation = {
                field: element,
                section,
            };
            this.$refs.gapValidationModal.showModal();
        },
        handleClosePanel() {
            this.showSidePanel = false;
            this.activeFieldPanel = {
                field: null,
                section: null,
            };
        },

        // Handle Save Check -----------------

        async handleSaveCheck() {
            let checkInfo = this.checkData;
            let url = `/tenant-check/${this.tenantId}/${checkInfo.id}`;
            let playload = {
                name: checkInfo.name,
                description: checkInfo.description,
                sla: parseInt(checkInfo.sla),
                parameters: checkInfo.parameters && checkInfo.parameters.parameters ? JSON.stringify(checkInfo.parameters.parameters) : "[]",
            };
            this.isSaving = true
            try {
                let { data } = await axios.put(url, playload);
                this.$toast.success(data.message);
            } catch (error) {
                this.$toast.error(error?.response?.data?.detail || "Failed to update check");
            }
            this.isSaving = false
        },

        //  Save Check End -----------------

        // mandatory permission select karne pe write/read/visible selected hona chahiye by default
        // write permission select karne pe read & visibel select hone chahiye by default
        // read karne be visible select hona chahiye by default

        PermissionChange(role) {
            if (role.role_mandatory) {
                role.role_read = true;
                role.role_write = true;
                role.role_visible = true;
                role.select_all = true;
            } else if (role.role_write) {
                role.role_read = true;
                role.role_visible = true;
                role.select_all = false;
            } else if (role.role_read) {
                role.role_visible = true;
                role.select_all = false;
            }
        },

        // Expand all sections

        handleExpandAllSections() {
            this.expandAllSection = !this.expandAllSection;
            this.sectionList = this.sectionList.map((el) => ({
                ...el,
                // expand all child blocks:
                fields: el.fields.map((field) => {
                    if (field.entity_type === "field") return field;
                    else if (field.entity_type === "block") {
                        return {
                            ...field,
                            expanded: this.expandAllSection,
                        };
                    }
                }),
                expanded: this.expandAllSection,
            }));
        },

        async showBlockModal(section) {
            this.activeSection = section;
            this.blockSearchValue = ""
            this.$refs["block-modal"].showBlockModal();
            await this.fetchBlockLists();
        },
        async fetchBlockLists() {
            this.loadingLists = true;
            try {
                let url = `/blocks`;
                let { data } = await axios.get(url);
                this.blockLists = data.data || [];
            } catch (error) {
                console.log(error, ">>>>>error");
            }
            this.loadingLists = false;
        },

        onChangehandleFilterResult(searchValue) {
            this.blockSearchValue = searchValue;
        },

        // Update Dependendta Field label
        async updateDependentsFieldLabel(field) {
            const url = `/tenant-check/${this.checkData.id}/section/${field.section_id}/field`;
            let payload = {
                section_field_id: field.id,
                label: field.name,
                permissions: field.permissions,
                report_visibility: field.report_visibility,
            };
            try {
                const { data } = await axios.put(url, payload);
                this.$toast.success(data.message);
            } catch (error) {
                this.$toast.error(error?.response?.data?.detail || "Failed to rename field name!");
            }
        },
        async getDependents(field_id, option_value) {
            const result = await axios.get(`tenant-check/field/${field_id}/options/${option_value}/dependents`);
            return result;
        },
        async getOptions(field_id) {
            const result = await axios.get(`tenant-check/field/${field_id}/options`);
            return result;
        },
        async changeCaseCreation(payload) {
            try {
                const result = await axios.patch(`tenant-check/caseform/${payload.id}`, {
                    state: payload.use_in_case_creation || false,
                });
                if (result) {
                    this.$toast.success(payload.use_in_case_creation ? `Using in case creation` : `Removed from case creation`);
                }
            } catch (error) {
                this.$toast.error(error.response.data.detail || `Something went wrong`);
                const setCreationVal = (fld) => {
                    return fld.id === payload.id ? { ...fld, use_in_case_creation: !payload.use_in_case_creation || false } : fld
                }
                this.sectionList = this.sectionList.map(sec => {
                    return {
                        ...sec, fields: sec.fields.map(fld => {
                            if (fld.entity_type === "field") {
                                return setCreationVal(fld)
                            } else if (fld.entity_type === "block") {
                                // for field type returning use_in_case_creation as false 
                                return { ...fld, fields: fld.fields.map(el => setCreationVal(el)) }
                            }
                        })
                    }
                })
            }
        },
    },
};
